<template>
    <div class="brand-list" v-loading="loading">
        <div class="banner-wrap">
            <el-carousel height="400px" v-loading="loadingAd">
                <el-carousel-item v-for="item in adList" :key="item.adv_id">
                    <el-image class="el-image__inner" style="object-fit: cover;" :src="$img(item.adv_image)"
                        @click="$router.pushToTab(item.adv_url.url)" />
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="banner-hd">
            <h3 class="title" v-text="local_lacma"></h3>
            <!--            <h3 class="title">湖南省消费帮扶地方馆</h3>-->
        </div>
        <div class="content_recont">
            <ul>
                <li class="ani-img" v-for="(item, index) in websiteData" :key="index">
                    <router-link target="_blank"
                        :to="{ path: '/citylist', query: { website_id: item.site_id, website_type: item.website_type } }">
                        <!--                        <span>{{item.title}}</span>-->
                        <img :src="item.img">
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="footerimg" v-if="adListGG.adv_image != ''">
            <img :src="adListGG.adv_image" width="1210" height="100" />
        </div>
    </div>
</template>

<script>
import { websiteList } from "@/api/goods/goods";
import { adList } from "@/api/website";
import Config from '@/utils/config';

export default {
    name: "list",
    components: {
    },
    computed: {
    },
    data: () => {
        return {
            websiteData: [],
            adList: [],
            adListGG: {
                adv_image: ''
            },
            loading: true,
            loadingAd: true,
            local_lacma: Config.localLacma
        }
    },
    created() {
    },
    mounted() {
        // console.log('Config.localLacma', Config.localLacma)
        this.getWebsiteList()
        this.getAdList()
        this.getAdListGG()
    },
    methods: {
        getAdList() {
            adList({ keyword: 'NS_PC_LOCALTUBE' })
                .then(res => {
                    this.adList = res.data.adv_list;
                    for (let i = 0; i < this.adList.length; i++) {
                        if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url);
                    }
                    this.loadingAd = false;
                })
                .catch(err => {
                    this.loadingAd = false;
                });
        },
        getAdListGG() {
            adList({ keyword: 'NS_PC_SPECIAL' })
                .then(res => {
                    if (res.data.adv_list.length > 0) {
                        this.adListGG = res.data.adv_list[0];
                    }
                })
        },
        //获取城市分站集合
        getWebsiteList() {
            websiteList({ type: 1 })
                .then(res => {
                    if (res.code == 0) {
                        this.websiteData = res.data
                        this.loading = false;
                    }
                })
                .catch(err => {
                    this.loading = false;
                    this.$message.error(err.message);
                });
        },
    }
};
</script>

<style lang="scss">
#app {
    background-color: #f7f7f7;
}

#app .el-main {
    width: 100%;
}

.el-image {
    width: 100%
}

.el-carousel__arrow {
    background-color: rgb(31 45 61 / 40%)
}

.el-carousel__arrow--right {
    right: 150px
}

.el-carousel__arrow--left {
    left: 150px
}
</style>
<style lang="scss" scoped>
.banner-wrap {
    width: 100%;
    height: 400px;
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;

    img {
        height: 100%;
        width: 100%;
    }
}

.banner-hd {
    width: 1210px;
    position: relative;
    margin: 0px auto;

    .title {
        display: inline-block;
        font-size: 24px;
        color: #424242;
        border-left: 6px solid #f50a31;
        line-height: 24px;
        padding-left: 10px;
        margin: 20px 0 10px;
        font-weight: normal;
    }
}

.footerimg {
    width: 1210px;
    position: relative;
    margin: 10px auto;
}

.content_recont {
    overflow: hidden;
    width: 1210px;
    position: relative;
    margin: 0 auto;
}

.content_recont ul {
    width: 1220px;
}

.content_recont ul li {
    float: left;
    width: 230px;
    height: 230px;
    overflow: hidden;
    margin-right: 10px;
    margin-top: 10px;
    position: relative;
    background-color: #0bb2d4;

    a {
        width: 100%;
        height: 100%;
        position: relative;
        display: block;

        img {
            width: 100%;
            height: 100%;
            border: none;
        }
    }

    span {
        position: absolute;
        width: 150px;
        height: 40px;
        font-size: 26px;
        line-height: 40px;
        font-weight: 700;
        color: #fff;
        top: 25px;
        left: 25px;
    }
}

.content_recont ul li:nth-of-type(3),
.content_recont ul li:nth-of-type(7),
.content_recont ul li:nth-of-type(10),
.content_recont ul li:nth-of-type(12) {
    width: 400px;
    background-color: #ff850f;
}

.content_recont ul li:nth-of-type(4),
.content_recont ul li:nth-of-type(7),
.content_recont ul li:nth-of-type(14) {
    width: 320px;
    background-color: #ff7674;
}

.content_recont ul li:nth-of-type(1),
.content_recont ul li:nth-of-type(2) {
    width: 600px;
    background-color: #b070e4;
}
</style>
